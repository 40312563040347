import P from 'prop-types';
import * as Styled from './styles';
const OpenCloseButton = ({ text, open = false, toggleFn }) => {

    return (
        <Styled.Container onClick={toggleFn}>
            <Styled.Hamburger isChecked={open} >
                <Styled.Active />
            </Styled.Hamburger>
                {text}
        </Styled.Container>
    );
};
OpenCloseButton.propTypes = {
    text: P.string,
    open: P.bool,
    toggleFn: P.func
};
export default OpenCloseButton;