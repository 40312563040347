import P from 'prop-types';
import * as Styled from './styles';
const ThumbnailButton = ({ clickFn, imageUrl }) => {
    return (
        <Styled.Container onClick={clickFn}>
            <img src={imageUrl}/>
        </Styled.Container>
    );
};
ThumbnailButton.propTypes = {
    clickFn: P.func,
    imageUrl: P.string.isRequired
};
export default ThumbnailButton;