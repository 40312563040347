import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            border-radius: ${theme.rounds.small}; 
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
        text-align: left;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.eightColor};
        font-weight: 300;
        font-size: 16px;
    `}
`;

export const ButtonsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: ${(theme.spacings.gutter * 2)+ (theme.spacings.gridColumn * 3)}px;
        margin: auto;
        justify-content: space-evenly;
        gap: ${ theme.spacings.gutter }px;
        padding: ${ theme.spacings.gutter }px 0;

    `}
`;
export const Button = styled.a.attrs({ target: '_blank' })`
    ${({ theme }) => css`
        font-family: ${theme.fonts.mainFont};
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        color: ${theme.colors.secondaryColor};
        background-color: ${theme.colors.mainColor};
        font-size: 12px;
        font-weight: 600;
        padding: 18px 58px;
        border-radius: ${theme.rounds.big};
        :hover {
            color:${theme.colors.terciaryColor};
        }
    `}
`;