import styled, { css } from 'styled-components';
export const Container = styled.button`
    ${({ theme }) => css`
        display: flex;
        gap: 5px;
        align-items: center;

        background: unset;
        border: none;
        outline: none;

        text-align: left;
        font: normal normal bold 14px/28px ${theme.fonts.mainFont};
        letter-spacing: 0px;
        color: ${theme.colors.secondaryColor};
    `}
`;

export const Hamburger = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 100%;
        padding: 5px;
        box-sizing: border-box;
        transition: ease 0.2s;
        background: ${theme.colors.terciaryColor};
        :hover{
            background: ${theme.colors.secondaryColor};
            border: #fe0565;
        }
    `}
`;

export const Active = styled.span`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        height: 1.5px;
        background: white;
        border-radius: 10px;
        transform: ${({ isChecked }) => (isChecked ? "rotate(-45deg)" : "none")};

        ::before,
        ::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: white;
            border-radius: 10px;
            transition: 0.3s;
        }
        ::before {
            top: ${({ isChecked }) => (isChecked ? "0" : "-3px")};
            left: 0;
            transform: ${({ isChecked }) => (isChecked ? "rotate(90deg)" : "none")};
        }
        ::after {
            top: ${({ isChecked }) => (isChecked ? "0" : "3px")};
            left: 0;
        }
    `}
`
