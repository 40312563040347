import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';

import ThumbnailButton from '../../components/ThumbnailButton';
import { useEffect } from 'react';
import iconClose from '../../assets/icon-close-2.png';

import TransparentContainer from '../../components/TransparentContainer';

const MultiplosVideosModal = ({ closeFn, videos = [
    { link:'CpNinwn37gA'},
    {link:'ebuqxqs52qM'},
    {link: 'U1-pmhnFuqo'}
]
}) => {
    const theme = useTheme(); 

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Conteúdos Bônus';
        return () => {
            document.title = title;
        }
    },[]);

    return (    <Styled.Content>
                        <Styled.CloseButton onClick={closeFn}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'40%'} center={true}>
                            <TransparentContainer bgColor={theme.colors.mainColor} alpha={0.95}>
                                <Styled.TitleWrapper>
                                    <Styled.MainHeading>Multiplos Videos</Styled.MainHeading>
                                    <Styled.Paragraph>Modal para multiplos</Styled.Paragraph>
                                </Styled.TitleWrapper>
                            </TransparentContainer>
                        </Styled.Block>
                        <Styled.Block flexBasis={'60%'} altPadding={true}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.95}>
                                {videos &&  
                                    <Styled.VideosWrapper>
                                        {videos.length > 0 && videos.map((item, i) =>  <ThumbnailButton 
                                                        clickFn={() => console.lo(item)}
                                                        imageUrl={`https://img.youtube.com/vi/${item.link}/0.jpg`} 
                                                        key={`${i + (i * i )}`} 
                                                        /> )}
                                    </Styled.VideosWrapper>
                                }
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
    );
};
MultiplosVideosModal.propTypes = {};
export default MultiplosVideosModal;