import styled, { css } from 'styled-components';
export const Container = styled.button`
    ${({ theme }) => css`
    top: 671px;
    left: 355px;
    /* width: 100%; */
    width: 18%;
    padding: 5px 15px;
    height: 100%;
    min-height: 40px;
    background: #FFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #69696978; */
    border-radius: 50px;
    border-color: ${theme.colors.mainColor};
    opacity: 1;
    backdrop-filter: blur(12px);
    font-family: ${theme.fonts.mainFont};
    text-align: center;
    font: normal normal bold 11px/21px ${theme.fonts.mainFont};
    letter-spacing: -0.1px;
    color: ${theme.colors.mainColor};
    text-transform: uppercase;
    transition: ease 0.2s;
    cursor: pointer;
    user-select: none;
    
    font-size: 8px;

    :hover {
        background: ${theme.colors.mainColor} 0% 0% no-repeat padding-box;
        /* box-shadow: inset 0px 3px 6px ${theme.colors.secondaryColor}, 1px 5px 16px ${theme.colors.secondaryColor}; */
        backdrop-filter: blur(20px);
        color: ${theme.colors.secondaryColor};
        border-color: transparent;
    }
    @media (max-width: 768px){
        width: 45%;
    }
    `}
`;