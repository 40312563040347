import P from 'prop-types';
import * as Styled from './styles';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';

const LinkExternoModal = ( { closeFn }) => {
    const theme = useTheme();

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Link';
        return () => {
            document.title = title;
        }
    },[]);

    return (<Styled.Content>
                        <Styled.CloseButton onClick={closeFn}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.95}>
                                <Styled.MainHeading>Titulo do Link Externo</Styled.MainHeading>
                                <Styled.Paragraph>Informacoes</Styled.Paragraph>
                                <Styled.ButtonsContainer>
                                    <Styled.Button href="https://www.sebrae.pr.br">Link externo</Styled.Button>
                                </Styled.ButtonsContainer>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
        
    );
};
LinkExternoModal.propTypes = {};
export default LinkExternoModal;