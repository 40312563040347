export const theme = {
    colors:{
        white: '#fff',
        black: '#000',
        mainColor: "#5653F7", 
        // mainColor: "#5c54f6", 
        secondaryColor: "#aaff01",
        terciaryColor: '#EB4AD5',
        quaternaryColor: '#FFC82F',
        fifthColor: '#6158F0',
        sixthColor: '#BCBEF9',
        sevenColor: '#ffc0cb',
        eightColor: '#1B3754',
        primaryGray: '#5ab2fb99',
        secondaryGray: '#D1D4D3',
        transparent: '#00000000',
    },
    fonts:{
        mainFont: 'Museo Sans',
        secondaryFont: 'Roboto'
    },
    spacings:{
        gutter: 30,
        gridColumn: 70,
        menuSpacing: 80,
    },
    rounds: {
        small: '4px',
        big: '50px',
        circle: '50%',
    },
    transitions:{
        timing: {
            mainTiming: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)'
        }
    }
}