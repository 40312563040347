import styled, { css } from 'styled-components';

import { Container as ModalContainer } from '../../components/ModalFixedSize/styles';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
import { Container as ThumbnailButtonContainer } from '../../components/ThumbnailButton/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
        /* ${ModalContainer}{
            .modal-content {
                padding: 0 100px;
            }
        } */
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis, center, altPadding }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${altPadding ? theme.spacings.gutter + (theme.spacings.gutter / 2) : theme.spacings.gutter}px;
            flex-grow: 1;
            display: ${center ? 'flex' : 'block'};
        }
    `}
`;
export const TitleWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacings.gutter / 2 }px;
        @media (max-width: 768px){
            
        }
    `}
`;
export const PinWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        padding: 0 54px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        @media (max-width: 768px){
            flex-direction: row;
        }
    `}
`;
export const Pin = styled.img`
    ${({ theme }) => css`
        margin: 0 auto;  
        @media (max-width: 768px){
            margin: auto;
        }
    `}
`;

export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;
export const Button = styled.button`
    ${({ theme }) => css`
        font-family: ${theme.fonts.mainFont};
        text-transform: uppercase;
        color: ${theme.colors.secondaryColor};
        /* padding: ${theme.spacings.gutter / 2 }px; */
        padding: 14px 34px;
        background-color: ${theme.colors.mainColor};
        border: 1px solid transparent;
        border-radius: ${theme.rounds.big};
        width: fit-content;
        align-self: flex-end;
        font-weight: 600;
        font-size: 10px;
        img {
            margin-right: 10px;
        }
        @media (max-width: 768px){
            align-self: center;
        }
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-family: ${theme.fonts.mainFont};
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 50;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.terciaryColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: left;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.eightColor};
        font-weight: 300;
        font-size: 13px;
    `}
`;
