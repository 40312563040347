import ModalFlexSize from "../../components/ModalFixedSize";
import Modal from '../Modal';

import MultiplosVideosModal from '../MultiplosVideosModal';
import VideoModal from '../VideoModal';
import LinkExternoModal from '../LinkExternoModal';

const ModalBlock = ({ data, open, openFn, closeFn }) => {
    // console.log(data)

    const modalType = () => {
      console.log(data.type)
      switch(data.type){
        case 3:
          return <VideoModal closeFn={closeFn} />
        case 4:
          return <MultiplosVideosModal closeFn={closeFn} />
        case 9:
          return <LinkExternoModal closeFn={closeFn} />
        default:
          return <MultiplosVideosModal closeFn={closeFn} />
 
      }
      
    }

    return (<div>
        { open && <ModalFlexSize 
                  headerText={data.name}
                  show={open}
                  onHideFn={closeFn}
                  >
                    <Modal closeFn={closeFn}>
                      <h2>{data.name}</h2>
                      {modalType()}
                    </Modal>
                </ModalFlexSize>}
    </div>)
}

export default ModalBlock;