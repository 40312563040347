import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
import { Container as ThumbnailButtonContainer } from '../../components/ThumbnailButton/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis, center, altPadding }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${altPadding ? theme.spacings.gutter + (theme.spacings.gutter / 2) : theme.spacings.gutter}px;
            flex-grow: 1;
            display: ${center ? 'flex' : 'block'};
            @media (max-width: 768px){
                flex-direction: column;
                padding: ${theme.spacings.gutter}px;
            }
        }
    `}
`;
export const TitleWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        margin: auto;
    `}
`
export const VideosWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: ${theme.spacings.gutter}px;
        overflow-y: auto;
        max-height: 80vh;
        ${ThumbnailButtonContainer}{
            flex-basis: 45%;
            @media (max-width: 768px){
                flex-basis: 100%;
            }
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.secondaryColor};
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.terciaryColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: left;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.white};
        font-weight: 300;
        font-size: 16px;
    `}
`;
