import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';
import museoSans100italic from './fonts/museo_sans/museo-sans-100-italic.otf';
import museoSans100 from './fonts/museo_sans/museo-sans-100.otf';
import museoSans300italic from './fonts/museo_sans/museo-sans-300-italic.otf';
import museoSans300 from './fonts/museo_sans/museo-sans-300.otf';
import museoSans500 from './fonts/museo_sans/museo-sans-500.otf';
import museoSans700italic from './fonts/museo_sans/museo-sans-700-italic.otf';
import museoSans700 from './fonts/museo_sans/museo-sans-700.otf';
import museoSans900italic from './fonts/museo_sans/museo-sans-900-italic.otf';
import museoSans900 from './fonts/museo_sans/museo-sans-900.otf';

import 'bootstrap/dist/css/bootstrap.min.css';
import fakeBg from '../assets/fake-bg.png';

export const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 
body {
    background-color: ${theme.colors.mainGray};
    background-image: url(${fakeBg});
    width: 100vw;
    height: 100vh;
}
.chroma-backdrop {
    /* background-color: transparent; */
}
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans100italic});
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans100});
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans300italic});
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans300});
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans500});
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans700italic});
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans700});
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans900italic});
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans900});
    font-weight: 900;
    font-style: normal;
}
`