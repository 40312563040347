import P from 'prop-types';
import * as Styled from './styles';
import { useTheme } from 'styled-components';
import { useEffect } from 'react';
import ModalFlexSize from '../../components/ModalFlexSize';
import Video from '../../components/Video';
import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';

const VideoModal = ( { closeFn }) => {
    const theme = useTheme();

    useEffect(() => {
        const { title } = document;
        document.title = `${title} - video`;
        return () => {
            document.title = title;   
        }
    },[]);

    return (    <Styled.Content>
                    <Styled.CloseButton onClick={closeFn}>
                        <img src={iconClose} />
                    </Styled.CloseButton>
                    <Styled.Block flexBasis={'40%'} center={true}>
                        <TransparentContainer bgColor={theme.colors.mainColor} alpha={0.95}>
                            <div>
                                <Styled.MainHeading>titulo do video</Styled.MainHeading>
                                {/* <Styled.Paragraph></Styled.Paragraph> */}
                            </div>
                        </TransparentContainer>
                    </Styled.Block>
                    <Styled.Block flexBasis={'60%'}>
                        <TransparentContainer bgColor={theme.colors.white} alpha={0.95}>
                            <Video videoUrl={`https://youtube.com/embed/Ww2TV1DjkTE`}/>
                        </TransparentContainer>
                    </Styled.Block>
                </Styled.Content>
                
    );
};
VideoModal.propTypes = {};
export default VideoModal;