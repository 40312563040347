import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';
import {  useEffect } from 'react';

import iconClose from '../../assets/icon-close-2.png';
import iconFile from '../../assets/icon-file.png';
import pin from '../../assets/pin.png';

import TransparentContainer from '../../components/TransparentContainer';

const Modal = ({ children, closeFn }) => {
    const theme = useTheme();
    useEffect(() => {
        const { title } = document;
        document.title = title + '- Bem Vindo';
        return () => {
            document.title = title;
        }
    },[])

    return ( <Styled.Container>
                <Styled.Content>
                    <Styled.Block flexBasis={'70%'} altPadding={true}>
                    <Styled.CloseButton onClick={closeFn}><img src={iconClose} /></Styled.CloseButton>
                        <TransparentContainer bgColor={theme.colors.white} alpha={0.95}>
                            {children}
                        </TransparentContainer>
                    </Styled.Block>
                </Styled.Content>
            </Styled.Container>
    );
};
export default Modal;