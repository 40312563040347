import { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from './styles/layout';
import Menu from './components/Menu';
import ModalBlock from './templateComponents/ModalBlock';

function App() {

  useEffect(() =>{

    axios.get('data/data.json')
      .then(({data}) => setEspacos(formatToArrays(data)))
        .catch(console.warm)
  },[]);

  const formatToArrays = (obj) => {
    const result = [];
    Object.keys(obj).map((key) => {
      obj[key].forEach(item => result.push(item))
    });
    return result;
  } 

  // Menu
  const [open, setOpen] = useState(false);

  const [espacos, setEspacos] = useState([])
  const [conteudos, setConteudos] = useState([])
  const [modals, setModals] = useState( espacos.map((_) => (  false  )) );
  const [update, setUpdate] = useState(false);

  
  const buttons = espacos
  .map((item) => {
    console.log(item)
    return item
  })
  // .filter((data) => +data.active )
  .map(( { name, id  }, i) => ({
    text:name,
    clickFn:() => openModal(i)
    })
  );

  const closeModal = (index) => {
    setModals(prev => {
      const newState = prev;
      newState[index] = false;
      return newState;
    })
    setUpdate(prev => !prev)
  }

  const openModal = (index) => {
    setModals(prev => {
      const newState = prev;
      newState[index] = true;
      return newState;
    })
    setUpdate(prev => !prev)
  }
  return (
    <Layout>
      <Menu 
            buttons={buttons} 
            open={open}
            toggleFn={() => setOpen(!open)}
            />
            <>
              {espacos
              // .filter((a) => +a.active )
              .map((data, i) => <ModalBlock key={data.name} data={data} open={modals[i]} closeFn={() => closeModal(i)}/>  ) }
            </>
    </Layout>
  );
}

export default App;
