import { useState } from 'react';

import P from 'prop-types';
import OpenCloseButton from '../OpenCloseButton';
import MenuButton from '../MenuButton';
import * as Styled from './styles';



const Menu = ({  buttons = [],  logos = [], controls, open, toggleFn }) => {

    return (
        <Styled.Container>
            <Styled.Wrapper>
                <Styled.Navbar>
                    <OpenCloseButton text={'menu'} open={open} toggleFn={toggleFn} />
                    <Styled.LogosContainer>{logos.map((item, i) => item)}</Styled.LogosContainer>
                    {controls && <Styled.ControlsContainer>{controls}</Styled.ControlsContainer> }
                </Styled.Navbar>
                <Styled.MenuWrapper menuIsOpen={open}>
                    {buttons.map((item, i) => <MenuButton key={i} {...item} />)}
                </Styled.MenuWrapper>
            </Styled.Wrapper>
        </Styled.Container>
    );
};
Menu.propTypes = {
    // buttons: P.arrayOf(P.node),
    // logos: P.arrayOf(P.node)
};
export default Menu;