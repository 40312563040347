import { useEffect, useState } from 'react';
import P from 'prop-types';
import * as Styled from './styles';
const ModalFixedSize = ({ headerText, children, show, size = "md", onHideFn }) => {
    console.log(show)
    return (
        <Styled.Container show={show} onHide={onHideFn} size={size} centered >
            {(headerText === '' || headerText === undefined || headerText === null) ? null : <Styled.Header closeButton >{headerText}</Styled.Header> }
            { children }
        </Styled.Container>
    );
};
ModalFixedSize.propTypes = {
    headerText: P.string,
    children: P.node.isRequired,
};
export default ModalFixedSize;