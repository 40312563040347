import styled, { css } from 'styled-components';
import { rgba } from 'polished';
export const Container = styled.div`
    ${({ theme }) => css`
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 900;
        width: 100vw;
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        box-sizing: border-box;
        overflow: hidden;
        max-width: 1000px;
        width: 95vw;
        margin: 0 auto;
    `}
`
export const Navbar = styled.nav`
    ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 38px;
    padding: 0 20px;
    background-color: ${rgba(theme.colors.mainColor, 0.5)};
    /* background: transparent linear-gradient(268deg, #3ea5d8 0%, #6310c2 100%) 0%
        0% no-repeat padding-box; */
    border-radius: 9px 9px 0px 0px;
    backdrop-filter: blur(7px);

    > .menu-button {
        display: flex;
        gap: 5px;
        align-items: center;

        background: unset;
        border: none;
        outline: none;

        text-align: left;
        font: normal normal bold 14px/28px Segoe UI;
        letter-spacing: 0px;
        color: #ffffff;
    }
    > .logo-container {
        @media (max-width: 400px) {
            display: none;
        }
    }
    `}
`
export const MenuWrapper = styled.div`
    ${({ theme, menuIsOpen }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 15px;

        max-height: ${menuIsOpen ? "350px" : "0"};
        background: #ffffff 0% 0% no-repeat padding-box;
        padding: 15px 30px;
        padding-top: ${menuIsOpen ? "30px" : "0px"};
        padding-bottom: ${menuIsOpen ? "30px" : "0px"};
        transition: 0.3s;

        overflow-y: auto;
    `}
`;
export const MenuContent = styled.div`
    ${({ theme }) => css`
        display: grid;
        justify-items: center;
        gap: 15px;
        grid-template-columns: repeat(6, 1fr);
        width: 100%;
        padding-bottom: 10px;

        backdrop-filter: blur(6px);
        @media (max-width: 750px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 520px) {
            grid-template-columns: repeat(2, 1fr);
        }
    `}
`;

export const LogosContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 400px) {
            display: none;
        }
    `}
`;
export const ControlsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 400px) {
            display: none;
        }
    `}
`;