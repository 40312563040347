import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { GlobalStyles } from './global-styles';


export default function Layout({ children }){
    return  <ThemeProvider theme={theme}>
                <GlobalStyles />
                {children}              
            </ThemeProvider>
}