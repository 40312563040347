import P from 'prop-types';
import * as Styled from './styles';
const MenuButton = ({ text, clickFn }) => {
    return (
        <Styled.Container onClick={clickFn}>
            {text}
        </Styled.Container>
    );
};
MenuButton.propTypes = {
    text: P.string,
    clickFn: P.func
};
export default MenuButton;